import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import ContactPage from './contact';

const ContactPageVariant = ({data, location}) => {
  const { defaultData: { frontmatter: defaultFrontmatter },
  variantData: { frontmatter: variantFrontmatter } } = data || {};
  return (
    <ContactPage
      location={location}
      {...defaultFrontmatter}
      {...variantFrontmatter}
    />
  );
};

ContactPageVariant.propTypes = {
  data: PropTypes.shape({
    defaultData: PropTypes.object.isRequired,
    variantData: PropTypes.object.isRequired,
  }).isRequired,
};

export default ContactPageVariant;

export const contactPageVariantQuery = graphql`
  query ContactPageVariant {
    defaultData: markdownRemark(frontmatter: { name: { eq: "contact"} }) {
      frontmatter {
        atsOptions {
          option
        }
        sizeOptions {
          option
        }
        sourceOptions {
          option
        }
      }
    }
    variantData: markdownRemark(frontmatter: { name: { eq: "contactVariant"} }) {
      frontmatter {
        seo {
          description
          title
        }
        title
        cta
        subtitle
        features {
          img
          title
          text
        }
      }
    }
  }
`;
