import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import cx from 'classnames';

import getAllUrlParams from '../../helpers/getAllUrlParams'
import Layout from '../../components/Layout';
import ContactForm from '../../components/Forms/ContactForm';
import styles from './contact.module.scss';

const ContactPage = ({
  location,
}) => {
  const { confirm } = getAllUrlParams(location.href)
  return (
    <StaticQuery
      query={graphql`
      query ContactPage {
        markdownRemark(frontmatter: { name: { eq: "contact"} }) {
          frontmatter {
            seo {
              description
              title
            }
            cta
            successText
            title
            subtitle
            atsOptions {
              option
            }
            sizeOptions {
              option
            }
            sourceOptions {
              option
            }
            features {
              img
              title
              text
            }
          }
        }
      }
    `}
      render={({
      markdownRemark,
    }) => {
      const {
        seo,
        title,
        subtitle,
        atsOptions,
        sizeOptions,
        sourceOptions,
        features = [],
        cta,
        successText, 
      } = markdownRemark.frontmatter;
      return (
        <Layout seo={seo} location={location}>
          <div className={cx(styles.contact)}>
            <div className={cx('container', styles.contactFlex)}>
              <section className={cx(styles.form)}>
                <div className={cx(styles.heading)}>
                  {confirm ? (
                    <>
                      <h1>Thanks for reaching out!</h1>
                      { subtitle && <p><big>One of our team members will be in touch shortly to talk about great recruiting, amazing candidate experience and other ways to make the world a better place.</big></p> }
                    </>
                  ) : (
                    <>
                      <h1>{ title }</h1>
                      { subtitle && <p><big>{subtitle }</big></p> }
                    </>
                  )}
                </div>
                {!confirm && (
                  <>
                    <ContactForm
                      atsOptions={atsOptions}
                      sizeOptions={sizeOptions}
                      sourceOptions={sourceOptions}
                      cta={cta}
                      successText={successText}
                      location={location}
                    />
                    <p style={{fontFamily:'Lato'}}>
                    To report vulnerabilities with the Resource application, please contact
                      {' '}
                      <a href="mailto:security@resource.io">security@resource.io</a>.
                    </p>
                  </>
                )}
              </section>
              <section className={cx(styles.features)}>
                {features.map(feature => (
                  <div className={cx(styles.feature)} key={feature.title}>
                    <img src={feature.img} alt={feature.title} className={cx('mb-2')} />
                    <div>
                      <h4 className='mb-2'>{feature.title}</h4>
                      <p className='m-0'>{feature.text}</p>
                    </div>
                  </div>
                ))}
              </section>
            </div>
          </div>
          <script type="text/javascript">{`
              piAId = '824443';
              piCId = '1647';
              piHostname = 'pi.pardot.com';
              
              (function() {
                function async_load(){
                  var s = document.createElement('script'); s.type = 'text/javascript';
                  s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
                  var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
                }
                if(window.attachEvent) { window.attachEvent('onload', async_load); }
                else { window.addEventListener('load', async_load, false); }
              })();
            `}
          </script>
        </Layout>
      )
    }}
    />
);
  }

// ContactTemplate.propTypes = {
//   title: PropTypes.string.isRequired,
//   subtitle: PropTypes.string,
// };

// ContactTemplate.defaultProps = {
//   subtitle: null,
// };

// const ContactPage = ( {data, location} ) => {
//   const { markdownRemark: { frontmatter } } = data;
//   return (
//     <ContactTemplate
//       location={location}
//       // eslint-disable-next-line react/jsx-props-no-spreading
//       {...frontmatter}
//     />
//   );
// };

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default ContactPage;
